@import 'style';

.job {
	&-content {
		position: relative;

		:global(.wysiwyg) {
			div + div,
			h2 + div {
				margin-top: 15 * $px;
			}

			div + h2 {
				margin-top: 30 * $px;
			}

			h2,
			h3,
			h4,
			h5,
			p {
				&:empty {
					display: none;
				}
			}
		}
	}
}

.hero {
	position: relative;
	background-color: white;
	padding: 80 * $px 0;

	&.mod-image {
		padding: 150 * $px 0;
		color: white;
	}
}

.apply {
	position: relative;
}

.content {
	padding: 85 * $px 0;

	&-share {
		background-color: #f9f9f9;
	}
}

.form {
	background: $gradient;
	padding: 50px 0;
	&-title {
		color: #fff;
		margin-bottom: 20 * $px;
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
	}
	&-fields {
		text-align: left;
		width: 100%;
	}
	&-form {
		align-items: end;
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
		@include breakpoint('ipad-port') {
			display: flex;
		}
	}
	&-submit {
		color: #fff;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		margin-left: auto;
		margin-top: 25px;

		@include breakpoint('ipad-port') {
			margin-top: 0;
		}

		&:hover .form-submit {
			@include breakpoint('desktop') {
				&-text {
					border-color: white;
				}
				&-circle {
					transform: translateX(10px);
				}
			}
		}

		&-container {
			text-align: right;
			flex: 1;
			white-space: nowrap;
			@include breakpoint('ipad-port') {
				margin-left: 30px;
			}
		}
		&-text {
			line-height: 1.5;
			text-transform: uppercase;
			margin-right: 10 * $px;
			border-bottom: 1px solid transparent;
			transition: border 0.25s ease-out;
		}

		&-circle {
			color: $blue;
			width: 40 * $px;
			height: 40 * $px;
			border-radius: 100%;
			background-color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.25s ease-out;

			&-icon {
				font-size: 12 * $px;
			}
		}
	}

	&-thanks {
		color: #fff;
		margin-bottom: 20 * $px;
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
		font-size: 20 * $px;
		line-height: 30 * $px;
		padding: 27 * $px 0;
	}
}
