@import 'style';

.podcast {
	color: white;
	padding: 40 * $px 0;
	background-color: $dark-grey;

	&-platforms {
		font-size: 1.35em;
	}
}

.episode {
	position: relative;
	padding: 40px 0;
	& + & {
		border-top: 1px solid grey;
	}

	&-description {
		margin-top: 10 * $px;
	}
}
