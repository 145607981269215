@import 'style';

.euprojects {
	&-space {
		height: 30 * $px;
		background-color: $background;
	}

	&-content {
		padding: 40 * $px 0;
	}
}

.item {
	padding: 28 * $px 0;

	&-image {
		position: relative;
	}

	&-content {
		&-title {
			font-size: 25 * $px;
			font-weight: 700;
		}

		&-description {
			margin: 20 * $px 0;
		}

		&-link {
			color: #3f98bd;
			border-bottom: 1px solid transparent;
			transition: 0.3s ease-out;

			&:hover {
				border-bottom: 1px solid #3f98bd;
			}
		}
	}
}

.detail {
	&-contentsidebar {
		background-color: white;
		padding-top: 65 * $px;
		padding-bottom: 35 * $px;
		&-sidebar {
			padding: 0 0 20 * $px 0;
		}
	}
	&-title {
		margin-top: 20 * $px;
		font-weight: 700;
	}

	&-link {
		color: #3f97bd;
		border-bottom: 1px solid transparent;
		transition: all 0.25s ease-out;
		word-break: break-word;
		&:hover {
			border-color: #3f97bd;
		}
	}

	&-tag {
		display: block;
		color: white;
		font-size: 12 * $px;
		line-height: 1.5;
		padding: 7 * $px 20 * $px;
		background-color: $dark-grey;
		border-radius: 50 * $px;
		border: 1px solid $dark-grey;
		transition: all 0.25s ease-out;

		&:hover {
			color: $text;
			background-color: transparent;
		}

		&-container {
			display: inline-block;
			vertical-align: top;
			margin-top: 10 * $px;
			padding-left: 10 * $px;
		}
	}

	&-logo {
		width: 100%;
		display: block;
		padding-bottom: 15px;
		max-width: 275px;
		height: auto;
	}
}
